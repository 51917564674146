import PropTypes from 'prop-types';
import { FileButton, Group, Stack, Text, UnstyledButton } from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { File, Upload, X } from "tabler-icons-react";
import { useOs } from '@mantine/hooks';
import { useState } from 'react';

export default function FullscreenDropzone({
  maxSize,
  maxFiles,
  titleMobile,
  titleDesktop,
  subtitle,
  setFiles: addFiles,
}) { 
  const os = useOs();

  /** Hooks */
  const [ buttonHover, setButtonHover ] = useState(false);

  return (
    <>
      {/* Actual dropzone object */}
      <Dropzone.FullScreen
        active
        onDrop={ (files) => addFiles(files) }
        onReject={ (files) => console.log('rejected files', files) }
        maxSize={ maxSize }
        maxFiles={ maxFiles }
        sx={() => ({
          '& .mantine-Dropzone-inner': { 
            height: '100%',
          }
        })}
      >
        <Group
          position='center'
          spacing='md'
          style={{ 
            pointerEvents: 'none',
            padding: 20,
            minHeight: '100%',
          }}
        >
          <Dropzone.Accept>
            <Upload
              size={ 50 }
              strokeWidth={ 1.5 }
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <X
              size={ 50 }
              strokeWidth={ 1.5 }
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <File
              size={ 50 }
              strokeWidth={ 1.5 }
            />
          </Dropzone.Idle>

          <Stack>
            <Text 
              size='xl' 
              weight={ 800 } 
              inline
            >
              { os === 'ios' || os === 'android' ? 
                titleMobile : titleDesktop
              }
            </Text>
            <Text 
              size='sm' 
              color='dimmed' 
              inline 
              mt={ 7 }
            >
              { subtitle }
            </Text>
          </Stack>
        </Group>
      </Dropzone.FullScreen>

      {/* Additional file upload element */}
      <FileButton
        multiple
        onChange={ addFiles }
      >
        { (props) =>  
          <UnstyledButton
            onMouseEnter={() => setButtonHover(true) }
            onMouseLeave={() => setButtonHover(false) }
            sx={(theme) => ({ 
              backgroundColor: buttonHover === true ? 
                theme.colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.gray[2]
                :
                theme.colorScheme === 'dark' ? theme.colors.gray[9] : theme.colors.gray[1],
              borderRadius: theme.radius.md,
            })}
            { ...props }
          >
            <Group
              position='center'
              sx={(theme) => ({
                padding: theme.spacing.xl,
                minHeight: 150
              })}
            >
              <File 
                size={ 50 }
                strokeWidth={ 1.5 }
              />

              <Stack
                style={{ gap: 5 }}
              >
                <Text 
                  size='xl' 
                  weight={ 800 } 
                  inline
                >
                  { os === 'ios' || os === 'android' ? 
                    titleMobile : titleDesktop
                  }
                </Text>
                <Text 
                  size='sm' 
                  color='dimmed' 
                  inline 
                  mt={ 7 }
                >
                  { subtitle }
                </Text>
              </Stack>
            </Group>
          </UnstyledButton>
        }
      </FileButton>
    </>
  )
}

FullscreenDropzone.propTypes = { 
  maxSize: PropTypes.number,
  maxFiles: PropTypes.number,
  titleMobile: PropTypes.string.isRequired,
  titleDesktop: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
}