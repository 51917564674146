import { List, Modal, Space, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";

export default function LimitsModal(props: any) {
  const modalOpened = props.modalOpened
  const setModalOpened = props.setModalOpened

  /** Hooks */
  const { t } = useTranslation();

  return (
    <Modal
      opened={modalOpened}
      onClose={() => setModalOpened(false)}
      title={
        <Title
          order={2}
          sx={(style) => ({ color: style.colors.cyan[5] })}
        >
          {t('limitsModal.titles.main', { ns: 'common' })}
        </Title>
      }
      radius='md'
      centered
      closeOnEscape
      closeOnClickOutside
      overlayProps={{
        opacity: 0.7,
        blur: 5,
      }}
    >
      <Text>
        {t('limitsModal.texts.intro', { ns: 'common' })}
      </Text>

      <Space h='md' />

      <Title order={4}>
        {t('limitsModal.titles.regularUsers', { ns: 'common' })}
      </Title>
      <List>
        {t('limitsModal.lists.regularUsers', { ns: 'common', joinArrays: '+' }).split('+').map((obj: string) =>
          <List.Item key={obj}>
            {obj}
          </List.Item>
        )}
      </List>
      <Space h='md' />

      <Title order={4}>
        {t('limitsModal.titles.proUsers', { ns: 'common' })}
      </Title>
      <List>
        {t('limitsModal.lists.proUsers', { ns: 'common', joinArrays: '+' }).split('+').map((obj: string) =>
          <List.Item key={obj}>
            {obj}
          </List.Item>
        )}
      </List>
      <Space h='md' />

      <Text>
        {t('limitsModal.texts.aboutPro', { ns: 'common' })}
      </Text>
    </Modal>
  )
}