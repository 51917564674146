/** Calculates the URL to download a drop based upon the current window origin.
 * @param code - The code of the Drop
 */
export function calculateDropUrl(code: string) {
    return(`${ window.location.origin }/?code=${code}`)
}

/** Generates a readable string from an amount of raw bytes
 * @param bytes - The raw bytes to work with
 */
export function normaliseBytes(bytes: number) { 
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return '0B'
    const i = Math.floor(Math.log(bytes) / Math.log(1000))
    if (i === 0) return `${bytes} ${sizes[i]})`
    return `${(bytes / (1000 ** i)).toFixed(2)} ${sizes[i]}`
}

/** Make a fixed-length number to the number of digits; if a number has less digits, it will be padded with zeros */
export function normaliseNumber(number: number, digits: number) { 

    let string = number.toString();
    let length = string.length;

    if (length < digits) {
        for (let i = 0; i < digits - length; i++) {
            string = '0' + string;
        }
    }

    return string;
}

/** Calculates the time between one date and the other */
export function calculateDeltaTime(dateOne: Date, dateTwo: Date) {
    const deltaTime = Math.abs((dateOne.getTime() - dateTwo.getTime()) / 1000);
    return new Date(deltaTime * 1000).toISOString().substr(11, 5);
}