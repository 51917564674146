import { Button, Select, Skeleton, Space, Table, Text, Title } from "@mantine/core";
import { useColorScheme, useDocumentTitle, useMediaQuery } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Droplet, ExternalLink, FileCheck, UserCircle } from "tabler-icons-react";
import PowerAppShell from "../components/appShell/Main";
import CenterPage from "../components/layout/centerPage";
import PageHeader from "../components/layout/pageHeader";
import LimitsModal from "../components/layout/modals/limitsModal";
import { normaliseBytes } from "../func/misc/helpers";
import { getUserByToken, User } from "../func/models/user";
import { useTranslation } from "react-i18next";
import TableRow from "../components/layout/TableRow";

export default function Settings() {

  // Hooks
  const { t } = useTranslation("settings");
  useDocumentTitle("Settings • Filedrop");
  const colorScheme = useColorScheme();
  const pageNavigate = useNavigate();

  // States
  const [ modalOpened, setModalOpened ] = useState(false);
  const [ pageLoading, setPageLoading ] = useState(false);
  const [ user, setUser ] = useState<User>(new User("", [], false, 0, 0));
  const [ defaultPage, setDefaultPage ] = useState("default");

  /** Get the user"s information */
  async function setUserData() { 
    const token = localStorage.getItem("token");
    if (!token) pageNavigate("/");

    setPageLoading(true);
    let user = await getUserByToken(token);
    if (!user) pageNavigate("/");
    setUser(user);
    setPageLoading(false);
  }

  function updateDefaultPage(page: string) { 
    localStorage.setItem("defaultPage", page);
    setDefaultPage(page);
  }

  /** Sign out */
  function signOut() { 
    window.localStorage.removeItem("token");
    pageNavigate("/");
  }

  /** Run on page load */
  useEffect(() => { 
    setUserData();

    // Get local variables
    setDefaultPage(localStorage.getItem("defaultPage") || "default");
  }, []);

  return(
    <>
      {/* ABOUT MODAL */}
      <LimitsModal 
        modalOpened={ modalOpened } 
        setModalOpened={ setModalOpened } 
      />

      {/* MAIN PAGE */}
      <PowerAppShell
        currentPage="settings"
      >
        <CenterPage alignTop>
          {/* TITLE */}
          <PageHeader>
            <Title order={ 1 }>
              { t("titles.main") }
            </Title>
          </PageHeader>

          {/* GENERAL */}
          <Title order={ 2 }>
            { t("titles.general") }
          </Title>

          {/* DEFAULT PAGE */}
          <Title order={ 4 }>
            { t("inputs.selects.defaultPage.title") }
          </Title>
          <Select
            placeholder="Select a page"
            value={ defaultPage }
            onChange={ updateDefaultPage }
            data={[
              { value: "default", label: t("inputs.selects.defaultPage.options.default") },
              { value: "upload", label: t("inputs.selects.defaultPage.options.upload") },
              { value: "drops", label: t("inputs.selects.defaultPage.options.drops") },
            ]}
            size="md"
            radius="md"
            variant="filled"
          />
          <Text
            size="sm"
          >
            { t("inputs.selects.defaultPage.description") }
          </Text>

          <Space h="xl" />

          {/* ACCOUNT */}
          <Title order={ 2 }>
            { t("titles.account") }
          </Title>

          <Skeleton visible={ pageLoading } radius="md">
            <Table verticalSpacing="sm">
              <tbody>
                <TableRow
                  icon={ <UserCircle strokeWidth={ 1.5 } /> }
                  name={ t("accountTable.accountType") }
                  value={ user.isProUser ? 
                    t("accountTable.accountTypes.pro")
                    : t("accountTable.accountTypes.reg")
                  }
                />
                <TableRow
                  icon={ <FileCheck strokeWidth={ 1.5 } /> }
                  name={ t("accountTable.storage") }
                  value={ `${ normaliseBytes(user.checkDataUsage()) }/${ normaliseBytes(user.maxStorage) }` }
                />
                <TableRow
                  icon={ <Droplet strokeWidth={ 1.5 } /> }
                  name={ t("accountTable.drops") }
                  value={ t("accountTable.dropsValue", { ns: "settings", amount: user.drops.length }) }
                />
              </tbody>
            </Table>
          </Skeleton>

          <Button
            color={ colorScheme === "dark" ? "gray" : "dark" }
            leftIcon={ <ExternalLink /> }
            radius="md"
            size="md"
            component="a"
            href="https://avant.isaacshea.com/dashboard/account"
            target="_blank"
          >
            { t("inputs.buttons.editAccount") }
          </Button>
          <Button
            color="dark"
            variant="subtle"
            radius="md"
            size="sm"
            onClick={() => signOut() }
          >
            { t("inputs.buttons.signOut") }
          </Button>
          <Button
            variant="subtle"
            radius="md"
            size="sm"
            onClick={() => setModalOpened(true) }
          >
            { t("inputs.buttons.dataLimits") }
          </Button>

          <Space h="xl" />

          {/* ABOUT */}
          <Title order={2}>
            { t("titles.about") }
          </Title>
          <Text>
            { t("texts.about") }
          </Text>

          <Space h="sm" />
            <Title order={4}>
            { t("titles.note") }
            </Title>
            <Text>
              { t("texts.note") }
            </Text>
        </CenterPage>
      </PowerAppShell>
    </>
  )
}