import { Box, Stack, Text, UnstyledButton } from "@mantine/core";
import PropTypes from 'prop-types';
import { useState } from "react";

export default function MobileNavbarButton({
  text,
  icon,
  isHighlighted,
  ...props
}) {
  const [isHover, setIsHover] = useState(false);

  return (
    <UnstyledButton
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      sx={(theme) => ({
        borderRadius: theme.radius.md,
        margin: theme.spacing.xs,
        textAlign: 'center',
        alignItems: 'center',
        flexGrow: 1,
      })}
      {...props}
    >
      <Stack style={{ gap: 5 }}>
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            initialWidth: 65,
            height: 41,
            padding: 5,
            borderRadius: 20,
            marginBottom: 0,
            backgroundColor: isHover === true ?
              theme.colorScheme === 'dark' ? theme.colors.gray[6] : theme.colors.gray[4]
              : isHighlighted === true ?
                theme.colorScheme === 'dark' ? theme.colors.gray[7] : theme.colors.gray[3]
                : null
          })}
        >
          {icon}
        </Box>

        <Text
          size='xs'
          sx={(theme) => ({
            marginTop: 0,
            color: isHover === true ?
              theme.black : theme.colors.gray[6]
          })}
        >
          {text}
        </Text>
      </Stack>
    </UnstyledButton>
  )
}

MobileNavbarButton.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  isHighighted: PropTypes.bool,
}