import PropTypes from 'prop-types';
import { Text } from "@mantine/core";

export default function TableRow({
  icon,
  name,
  value,
}) { 
  return ( 
    <tr>
      <td
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 10,
        }}
      >
        { icon } 
        <Text
          weight={ 800 }
        > 
          { name }
        </Text>
      </td>
      <td
        style={{ textAlign: 'right', }}
      >
        { value }
      </td>
    </tr>
  )
}

TableRow.propTypes = { 
  icon: PropTypes.node,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}