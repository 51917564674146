import PropTypes from 'prop-types'
import { Image } from "@mantine/core";

export default function QrCodeImage({
  url,
  alt
}) { 
  return (
    <Image
      radius='md'
      src={ `https://chart.googleapis.com/chart?cht=qr&chl=${ url }&chs=248` }
      alt={ alt }
      style={{ aspectRatio: "1/1" }}
    />
  );
}

QrCodeImage.propTypes = { 
  url: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}