import PropTypes from 'prop-types';
import { Button, CopyButton, Stack, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { calculateDropUrl } from '../../../func/misc/helpers';
import QrCodeImage from '../../misc/qrCodeImage';
import { GlassModal } from './glassModal';

export function ShareModal({
  code,
  name,
  opened,
  onClose
}) {
  const { t } = useTranslation();
  const dropLink = calculateDropUrl(code);

  return (
    <GlassModal
      opened={opened}
      onClose={onClose}
      centered
    >
      <Stack>
        <QrCodeImage
          url={dropLink}
          alt='QR code for the drop URL'
        />

        <Title
          order={2}
          align='center'
          color='white'
        >
          {name}
        </Title>

        <Text
          align='center'
          weight={800}
          color='white'
        >
          {dropLink}
        </Text>

        <CopyButton value={dropLink}>
          {({ copied, copy }) => (
            <Button
              size='md'
              radius='md'
              onClick={copy}
            >
              {copied ?
                t('copyLink.copied', { ns: 'common' })
                :
                t('copyLink.reg', { ns: 'common' })
              }
            </Button>
          )}
        </CopyButton>
      </Stack>
    </GlassModal>
  )
}

ShareModal.propTypes = {
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}