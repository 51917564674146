import { ActionIcon, Button, Center, Loader, Space, Text, Title } from "@mantine/core";
import { useDocumentTitle } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Share, Trash } from "tabler-icons-react";
import PowerAppShell from "../components/appShell/Main";
import CenterPage from "../components/layout/centerPage";
import PageHeader from "../components/layout/pageHeader";
import { calculateDeltaTime, normaliseBytes } from "../func/misc/helpers";
import { useTranslation } from "react-i18next";
import { getUserByToken, User } from "../func/models/user";
import ComplexButton from "../components/inputs/buttons/complexButton";
import ButtonStack from "../components/inputs/buttons/buttonStack";
import { ShareModal } from "../components/layout/modals/shareModal";
import { Drop } from "../func/models/drop";
import { showNotification } from "@mantine/notifications";
import { API_URL } from "../func/consts";

export default function Drops() {

  /** Hooks */
  const { t } = useTranslation("drops");
  useDocumentTitle("Your drops • Filedrop");
  const pageNavigate = useNavigate();

  // States
  const [pageLoading, setPageLoading] = useState(false);
  const [showRemaining, setShowRemaining] = useState(false);
  const [user, setUser] = useState<User>(new User(
    "",
    [],
    false,
    0,
    0
  ));
  const [shareModal, setShareModal] = useState({ code: "", name: "", });

  /** Get the user's information */
  async function setUserData() {
    const token = localStorage.getItem("token");
    if (!token) pageNavigate("/");

    setPageLoading(true);
    let user = await getUserByToken(token);
    if (!user) pageNavigate("/");
    setUser(user);
    setPageLoading(false);
  }

  /** Run on page load */
  useEffect(() => {
    setUserData();
  }, []);

  /** Open the share modal */
  function openShareModal(drop: Drop) {
    setShareModal({ code: drop.id, name: drop.name });
  }
  function closeShareModal() {
    setShareModal({ code: "", name: "" });
  }

  /** Delete a drop */
  async function deleteDrop(drop: Drop) {
    const token = localStorage.getItem("token");
    if (!token) pageNavigate("/");

    showNotification({
      message: t("alerts.deletingDrop.message", { name: drop.name }),
      color: "red",
    })

    let deleteRes = await fetch(`${API_URL}drop/${drop.id}`, {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });
    if (!deleteRes.ok) {
      showNotification({
        title: t("errors.unknownError.title", {
          ns: "common",
          status: deleteRes.status,
        }),
        message: t("errors.unknownError.message", {
          ns: "common",
        }),
        color: "red",
      });
      return;
    }

    /** Reload the page */
    await setUserData();
  }

  return (
    <>
      {/* SHARE MODAL */}
      <ShareModal
        code={shareModal.code}
        name={shareModal.name}
        opened={shareModal.name !== ""}
        onClose={closeShareModal}
      />

      {/* MAIN PAGE */}
      <PowerAppShell
        currentPage="drops"
      >
        <CenterPage alignTop>
          {/* TITLE */}
          <PageHeader>
            <Title order={1}>
              {t("titles.main")}
            </Title>

            <Text
              onClick={() => setShowRemaining(!showRemaining)}
              style={{
                cursor: "pointer",
                padding: 0
              }}
            >
              {showRemaining ?
                t("texts.dataStorage2", {
                  ns: "drops",
                  remainder: normaliseBytes(user.maxStorage - user.checkDataUsage()),
                })
                :
                t("texts.dataStorage1", {
                  ns: "drops",
                  usage: normaliseBytes(user.checkDataUsage()),
                  total: normaliseBytes(user.maxStorage),
                })
              }
            </Text>
          </PageHeader>

          {/* Loader / drops */}
          {pageLoading ?
            <Center style={{ height: "100%", flexGrow: 1 }}>
              <Loader />
            </Center>
            :
            user.drops.length === 0 ?
              <Center style={{ height: "100%", flexGrow: 1 }}>
                <Text size="md">
                  {t("texts.noDrops")}
                </Text>
              </Center>
              :
              <ButtonStack>
                {user.drops.map((drop, index) =>
                  <ComplexButton
                    onClick={() => {
                      window.open(`${window.location.origin}/drop?code=${drop.id}`, "_blank");
                    }}
                    key={index}
                    title={drop.name}
                    subtitle={t("inputs.buttons.dropButtonSubtitle", {
                      ns: "drops",
                      id: drop.id,
                      time: calculateDeltaTime(new Date, new Date(drop.expireDate)),
                      files: drop.files.length,
                    })}
                    isButton={true}
                    buttons={
                      <>
                        <ActionIcon
                          radius="xl"
                          color="dark"
                          onClick={(event: any) => {
                            event.stopPropagation();
                            openShareModal(drop);
                          }}
                        >
                          <Share size={20} />
                        </ActionIcon>
                        <ActionIcon
                          radius="xl"
                          color="red"
                          onClick={(event: any) => {
                            event.stopPropagation();
                            deleteDrop(drop);
                          }}
                        >
                          <Trash size={20} />
                        </ActionIcon>
                      </>
                    }
                  />
                )}
              </ButtonStack>
          }

          <Space h="xs" />

          {/* New drop button */}
          <Button
            radius="md"
            size="md"
            style={{ width: "100%" }}
            onClick={() => pageNavigate("/upload")}
          >
            {user.drops.length > 0 ?
              t("inputs.buttons.uploadAnother") :
              t("inputs.buttons.uploadDrop")
            }
          </Button>
        </CenterPage>
      </PowerAppShell>
    </>
  )
}