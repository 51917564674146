import plusJakartaReg from "./assets/fonts/PlusJakartaSans-Regular.ttf";
import plusJakartaItalic from "./assets/fonts/PlusJakartaSans-Italic.ttf";
import plusJakartaExtraBold from "./assets/fonts/PlusJakartaSans-ExtraBold.ttf";
import plusJakartaExtraBoldItalic from "./assets/fonts/PlusJakartaSans-ExtraBoldItalic.ttf";
import mono from "./assets/fonts/JetBrainsMono-Regular.ttf";
import monoBold from "./assets/fonts/JetBrainsMono-ExtraBold.ttf";

import { Global, MantineProvider } from "@mantine/core";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useColorScheme } from "@mantine/hooks";
import Login from "./pages/login";
import Drops from "./pages/drops";
import Settings from "./pages/settings";
import Home from "./pages/home";
import UploadPage from "./pages/upload";
import DownloadPage from "./pages/download";
import { Notifications } from "@mantine/notifications";

export default function App() {
  const colorScheme = useColorScheme();

  return (
    <>
      <Global
        // Note the format woff2; this is for some reason necessary
        styles={[
          {
            "@font-face": {
              fontFamily: "Plus Jakarta Sans",
              src: `url("${plusJakartaReg}") format("woff2")`,
              fontWeight: 400,
              fontStyle: "normal",
            },
          },
          {
            "@font-face": {
              fontFamily: "Plus Jakarta Sans",
              src: `url("${plusJakartaItalic}") format("woff2")`,
              fontWeight: 400,
              fontStyle: "italic",
            },
          },
          {
            "@font-face": {
              fontFamily: "Plus Jakarta Sans",
              src: `url("${plusJakartaExtraBold}") format("woff2")`,
              fontWeight: 800,
              fontStyle: "normal",
            },
          },
          {
            "@font-face": {
              fontFamily: "Plus Jakarta Sans",
              src: `url("${plusJakartaExtraBoldItalic}") format("woff2")`,
              fontWeight: 800,
              fontStyle: "italic",
            },
          },
          {
            "@font-face": {
              fontFamily: "JetBrains Mono",
              src: `url("${mono}") format("woff2")`,
              fontWeight: 400,
              fontStyle: "normal",
            },
          },
          {
            "@font-face": {
              fontFamily: "JetBrains Mono",
              src: `url("${monoBold}") format("woff2")`,
              fontWeight: 800,
              fontStyle: "normal",
            },
          },
        ]}
      />

      <BrowserRouter>
        {/* LOAD FONTS */}

        {/* STYLES */}
        <MantineProvider theme={{
          fontFamily: "Plus Jakarta Sans, sans-serif",
          fontFamilyMonospace: "JetBrains Mono, Courier New, monospace",
          headings: { fontFamily: `Plus Jakarta Sans, sans-serif`, fontWeight: 800 },
          colorScheme: colorScheme === "dark" ? "dark" : "light",
          white: colorScheme === "dark" ? "#11181C" : "#F8F9FA",
          black: colorScheme === "dark" ? "#F8F9FA" : "#11181C",
          primaryColor: "cyan",
          primaryShade: 5
        }} withGlobalStyles withNormalizeCSS>
          <Notifications />

          {/* ROUTES */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/drop" element={<DownloadPage />} />
            <Route path="/drops" element={<Drops />} />
            <Route path="/upload" element={<UploadPage />} />
            <Route path="/settings" element={<Settings />} />

            {/* MOBILE ONLY */}
            <Route path="/login" element={<Login />} />
          </Routes>
        </MantineProvider>
      </BrowserRouter>
    </>
  )
}