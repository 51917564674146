import { Button, Grid, Loader, TextInput, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { ArrowRight, Qrcode } from "tabler-icons-react";
import { useTranslation } from "react-i18next";
import { showNotification } from "@mantine/notifications";
import { API_URL } from "../func/consts";

export default function FileCode(props: any) {

	// Hooks
	const { t } = useTranslation("home");
	const pageNavigate = useNavigate();

	// States
	const [codeValue, setCodeValue] = useState("");
	const [loading, setLoading] = useState(false);

	// Functions
	async function changeCodeValue(value: string) {
		if (value.length <= 6) { setCodeValue(value.toUpperCase()); }
	}

	async function receiveFile(event: any, code: string) {
		if (event) { event.preventDefault(); }
		if (loading) return;
		if (!code.trim()) return;

		setLoading(true);

		const response = await fetch(`${API_URL}drop/${code}`);
		if (!response.ok) {
			if (response.status === 404) {
				showNotification({
					title: t("errors.dropNotFound.title"),
					message: t("errors.dropNotFound.message"),
					color: "red"
				});
			} else {
				showNotification({
					title: t("errors.unknownError.title", { ns: "common", status: response.status }),
					message: t("errors.unknownError.message", { ns: "common" }),
					color: "red"
				});
			}
		} else {
			// Redirect to the download page
			const dropData = await response.json();
			pageNavigate(`/drop?code=${dropData.id}`);
		}

		setCodeValue("");
		setLoading(false);
	}

	// Effects
	/** Check for 6 or more digits on the frInput and automatically trigger the form */
	useEffect(() => { if (codeValue.length >= 6) { receiveFile(null, codeValue); } });

	/** Detect a code in the URL */
	const search = useLocation().search;
	useEffect(() => {
		const dropCode = new URLSearchParams(search).get("code");
		if (dropCode) {
			// Prefill code
			setCodeValue(dropCode);

			// Attempt to send 
			receiveFile(null, dropCode);
		}
	}, []);

	// Return
	return (
		<form onSubmit={(event) => receiveFile(event, "")}>
			<Grid grow gutter="sm" style={{
				maxWidth: 500,
				margin: 20
			}}>
				<Grid.Col span={8}>
					<Title
						order={1}
						style={{ textAlign: "left" }}
					>
						{t("titles.receiveFile")}
					</Title>
				</Grid.Col>
				<Grid.Col span={7}>
					<TextInput
						placeholder={t("inputs.receiveFile.placeholder")}
						icon={<Qrcode size={14} />}
						variant="filled"
						radius="md"
						size="md"
						onChange={(event) => changeCodeValue(event.currentTarget.value)}
						value={codeValue}
						disabled={loading}
						autoFocus
					/>
				</Grid.Col>
				<Grid.Col span={1}>
					<Button
						type="submit"
						variant="white"
						radius="md"
						size="md"
						style={{ width: "100%" }}
					>
						{!loading ? <ArrowRight /> : <Loader size="sm" />}
					</Button>
				</Grid.Col>


				{/* MOBILE BUTTON */}
				{props.isMobile === false ? null :
					<>
						<Grid.Col span={8}>
							<Title
								order={2}
								style={{ textAlign: "center" }}
							>
								{t("titles.or")}
							</Title>
						</Grid.Col>
						<Grid.Col span={8}>
							<Button
								component={Link}
								to="/login"
								color="dark"
								radius="md"
								size="md"
								style={{ width: "100%" }}
								sx={(theme) => ({
									color: "white"
								})}
							>
								{t("inputs.buttons.signIn")}
							</Button>
						</Grid.Col>
					</>
				}
			</Grid>
		</form>
	)
}