export class FileObject { 
  id: string;
  name: string;
  size: number;
  file: any;

  /**
   * Creates a new File object
   * @param id - randomly-generated UUID identifier
   * @param name - original file name (including extension)
   * @param size - size in bytes of this file
   * @param file - actual file object from the R2 bucket
   */
  constructor(
    id: string,
    name: string,
    size: number,
    file: any,
  ) { 
    this.id = id;
    this.name = name;
    this.size = size;
    this.file = file;
  }
}