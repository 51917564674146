import { FileObject } from "./fileObject";

export class Drop { 
  id: string;
  creatorID: string;
  name: string;
  expireDate: Date;
  files: FileObject[];

  /**
   * Creates a Drop object
   * @param id - A 6-digit code generated to identify this drop
   * @param creatorID - The ID of the creator User
   * @param name - The name of the drop
   * @param expireDate - The expiry date of the file; generally 24 hours from it's creation
   * @param files - The files to add to this drop
   */
  constructor(
    id: string,
    creatorID: string,
    name: string,
    expireDate: Date,
    files: FileObject[],
  ) { 
    this.id = id;
    this.creatorID = creatorID;
    this.name = name;
    this.expireDate = expireDate;
    this.files = files;
  }
}