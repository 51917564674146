import { AUTH_URL } from "../consts";

export default class Creator { 
  id: string;
  name: string;
  status: number;

  /** 
   * Creates a creator object
   * @param id - unique user identifier supplied by the auth server
   * @param name - the username of the creator
   * @param status - the account status of the creator (almost always 1; here for continuity's sake)
   */
   constructor (
    id: string,
    name: string,
    status: number,
  ) { 
    this.id = id;
    this.name = name;
    this.status = status;
  }
}

export async function getCreator(id: string) { 
  let creatorRes = await fetch(`${ AUTH_URL }account/${ id }`);
  if (!creatorRes.ok) return;

  let creatorInfo: Creator = await creatorRes.json();
  return new Creator(
    creatorInfo.id,
    creatorInfo.name,
    creatorInfo.status 
  );
}

export async function getCreatorByToken(token: string) { 
  let creatorRes = await fetch(`${ AUTH_URL }account/me`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  });
  if (!creatorRes.ok) return;

  let creatorInfo: Creator = await creatorRes.json();
  return new Creator(
    creatorInfo.id,
    creatorInfo.name,
    creatorInfo.status 
  );
}