import { Modal, Table, Title } from '@mantine/core';
import Creator from '../../../func/models/creator';
import { Drop } from '../../../func/models/drop';
import { useTranslation } from "react-i18next";
import TableRow from '../TableRow';
import { FileCheck, Files, LetterCase, UserCircle } from 'tabler-icons-react';
import { normaliseBytes } from '../../../func/misc/helpers';

export default function InfoModal({
  dropInfo,
  creatorInfo,
  opened,
  onClose,
}) {
  const drop: Drop = dropInfo;
  const creator: Creator = creatorInfo;

  const { t } = useTranslation();

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      title={
        <Title order={2}>
          {t('aboutModal.title', { ns: 'common' })}
        </Title>
      }
      overlayProps={{
        opacity: 0.7,
        blur: 5,
      }}
    >
      <Table>
        <tbody>
          <TableRow
            icon={<LetterCase size={20} />}
            name={t('downloadModal.name', { ns: 'download' })}
            value={drop.name}
          />
          <TableRow
            icon={<FileCheck size={20} />}
            name={t('downloadModal.size', { ns: 'download' })}
            value={normaliseBytes(drop.files.reduce((acc, cur) => {
              return acc + cur.size;
            }, 0))}
          />
          <TableRow
            icon={<Files size={20} />}
            name={t('downloadModal.items', { ns: 'download' })}
            value={`${drop.files.length} items`}
          />
          <TableRow
            icon={<UserCircle size={20} />}
            name={t('downloadModal.sharedBy', { ns: 'download' })}
            value={`@${creator.name}`}
          />
        </tbody>
      </Table>
    </Modal>
  )
}