import { Group, Navbar } from '@mantine/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CloudUpload, Download, Files, Settings } from 'tabler-icons-react';
import MobileNavbarButton from './MobileButton';
import { useTranslation } from "react-i18next";

export default function MobileNav({
  currentPage
}) {
  const { t } = useTranslation();

  return (
    <Navbar
      fixed
      sx={(theme) => ({
        width: '100%',
        height: 'fit-content',
        bottom: 0,
        backgroundColor: theme.colorScheme === 'dark' ?
          theme.colors.gray[9] : theme.colors.gray[1],
        border: 'none',
      })}
    >
      <Group
        position='center'
        style={{
          justifyContent: 'space-around',
          alignItems: 'center',
          height: '100%',
          flexWrap: 'nowrap',
          gap: 0,
        }}
      >
        <MobileNavbarButton
          text={t('navButtons.receive', { ns: 'common' })}
          icon={<Download strokeWidth={1.5} />}
          isHighlighted={false}
          component={Link}
          to='/?receive=true'
        />
        <MobileNavbarButton
          text={t('navButtons.create', { ns: 'common' })}
          icon={<CloudUpload strokeWidth={1.5} />}
          isHighlighted={currentPage === 'upload'}
          component={Link}
          to='/upload'
        />
        <MobileNavbarButton
          text={t('navButtons.drops', { ns: 'common' })}
          icon={<Files strokeWidth={1.5} />}
          isHighlighted={currentPage === 'drops'}
          component={Link}
          to='/drops'
        />
        <MobileNavbarButton
          text={t('navButtons.settings', { ns: 'common' })}
          icon={<Settings strokeWidth={1.5} />}
          isHighlighted={currentPage === 'settings'}
          component={Link}
          to='/settings'
        />
      </Group>
    </Navbar>
  )
}

MobileNav.propTypes = {
  currentPage: PropTypes.string.isRequired,
}