import { ActionIcon, Button, Center, Group, Loader, Skeleton, Space, Text, Title } from "@mantine/core";
import { useDocumentTitle } from "@mantine/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import CenterPage from "../components/layout/centerPage";
import PageHeader from "../components/layout/pageHeader";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Drop } from "../func/models/drop";
import { showNotification } from "@mantine/notifications";
import { FileObject } from "../func/models/fileObject";
import ButtonStack from "../components/inputs/buttons/buttonStack";
import ComplexButton from "../components/inputs/buttons/complexButton";
import { normaliseBytes } from "../func/misc/helpers";
import { Download, InfoCircle, Share } from "tabler-icons-react";
import fileDownload from "../func/misc/download";
import { ShareModal } from "../components/layout/modals/shareModal";
import Creator from "../func/models/creator";
import InfoModal from "../components/layout/modals/infoModal";
import { API_URL, APP_TOKEN, AUTH_URL } from "../func/consts";

export default function DownloadPage() {

  /** Hooks */
  const { t } = useTranslation("download");
  const pageNavigate = useNavigate();
  const pageUrl = useLocation().search;
  const [docTitle, setDocTitle] = useState("Unknown drop • Filedrop");
  useDocumentTitle(docTitle);

  /** States */
  const [isLoading, setIsLoading] = useState(false);
  const [drop, setDrop] = useState<Drop>(new Drop("", "", "", new Date, []));
  const [creator, setCreator] = useState<Creator>(new Creator("", "", 0));
  const [isShareModalOpened, setIsShareModalOpened] = useState(false);
  const [isInfoModalOpened, setIsInfoModalOpened] = useState(false);

  /** Get drop & user */
  async function setDropInfo() {
    /** Get drop code from url */
    let dropID = new URLSearchParams(pageUrl).get("code");
    if (!dropID) pageNavigate("/");

    setIsLoading(true);
    let dropRes = await fetch(`${API_URL}drop/${dropID}`);
    if (!dropRes.ok) {
      if (dropRes.status === 410) {
        showNotification({
          title: t("errors.dropNotFound.title"),
          message: t("errors.dropNotFound.message"),
          color: "red",
          autoClose: false
        })
      } else {
        showNotification({
          title: t("errors.unknownError.title", {
            ns: "common",
            status: dropRes.status,
          }),
          message: t("errors.unknownError.message", {
            ns: "common",
          }),
          color: "red",
        });
      }
      return;
    }

    let dropInfo: Drop = await dropRes.json();
    setDrop(new Drop(
      dropInfo.id,
      dropInfo.creatorID,
      dropInfo.name,
      dropInfo.expireDate,
      dropInfo.files.map((file: FileObject) => new FileObject(
        file.id,
        file.name,
        file.size,
        file.file
      )),
    ));

    /** Get creator information from the auth server */
    let creatorRes = await fetch(`${AUTH_URL}account/${dropInfo.creatorID}`, { 
      headers: { 
        "Av-App-Token": APP_TOKEN,
      }
    });
    if (!creatorRes.ok) {
      showNotification({
        title: t("errors.unknownError.title", {
          ns: "common",
          status: creatorRes.status,
        }),
        message: t("errors.unknownError.message", {
          ns: "common",
        }),
        color: "red",
      });
      return;
    }

    let creatorInfo: Creator = await creatorRes.json();
    setCreator(new Creator(
      creatorInfo.id,
      creatorInfo.name,
      creatorInfo.status
    ));
    setIsLoading(false);

    /** Update the page metadata */
    setDocTitle(`${dropInfo.name} • Filedrop`);
  }

  /** Download a file */
  async function downloadFile(drop: Drop, file: FileObject) {
    let fileRes = await fetch(`${API_URL}drop/${drop.id}/file?id=${file.id}`);
    if (!fileRes.ok) {
      showNotification({
        title: t("errors.unknownError.title", {
          ns: "common",
          status: fileRes.status,
        }),
        message: t("errors.unknownError.message", {
          ns: "common",
        }),
        color: "red",
      });
      return;
    }

    const fileBlob = await fileRes.blob();
    fileDownload(fileBlob, file.name);
  }

  /** Download all files */
  async function downloadAllFiles(drop: Drop) {
    for (var file of drop.files) {
      downloadFile(drop, file);
    }
  }

  function closeShareModal() {
    setIsShareModalOpened(false)
  }
  function closeInfoModal() {
    setIsInfoModalOpened(false);
  }

  useEffect(() => {
    setDropInfo();
  }, []);

  return (
    <>
      {/* SHARE MODAL */}
      <ShareModal
        code={drop.id}
        name={drop.name}
        opened={isShareModalOpened}
        onClose={closeShareModal}
      />

      {/* INFO MODAL */}
      <InfoModal
        dropInfo={drop}
        creatorInfo={creator}
        opened={isInfoModalOpened}
        onClose={closeInfoModal}
      />

      {/* MAIN PAGE CONTENT */}
      <CenterPage
        alignTop
        padding={20}
      >
        {/* TITLE */}
        <PageHeader>
          <Skeleton visible={isLoading}>
            <Title order={1}>
              {drop.name ? drop.name : t("titles.placeholder")}
            </Title>
          </Skeleton>
          <Skeleton visible={isLoading}>
            <Text
              size="sm"
            >
              {t("texts.sharedBy")}
              <Text component="span" weight={800}>
                {creator.name}
              </Text>
              {` • ${drop.id}`}
            </Text>
          </Skeleton>
        </PageHeader>


        {/* FILES */}
        <Skeleton visible={isLoading}>
          <Title order={2}>
            {t("titles.files", { ns: "upload" })}
          </Title>
        </Skeleton>

        {isLoading ?
          <Center style={{ height: "100%", flexGrow: 1 }}>
            <Loader />
          </Center>
          :
          <ButtonStack>
            {drop.files.map((file) =>
              <ComplexButton
                key={file.name}
                title={file.name}
                subtitle={normaliseBytes(file.size)}
                isButton={true}
                onClick={() => downloadFile(drop, file)}
                buttons={
                  <ActionIcon
                    radius="xl"
                    color="cyan"
                  >
                    <Download size={20} />
                  </ActionIcon>
                }
              />
            )}
          </ButtonStack>
        }

        <Space h="xs" />

        {/* New drop button */}
        <Skeleton visible={isLoading}>
          <Button
            radius="md"
            size="md"
            style={{ width: "100%" }}
            onClick={() => downloadAllFiles(drop)}
          >
            {t("inputs.buttons.downloadAll")}
          </Button>
        </Skeleton>

        {/* Share & info buttons */}
        <Group grow>
          <Button
            radius="md"
            size="md"
            color="dark"
            variant="subtle"
            onClick={() => setIsInfoModalOpened(true)}
          >
            <InfoCircle size={20} />
          </Button>

          <Button
            radius="md"
            size="md"
            color="dark"
            variant="subtle"
            onClick={() => setIsShareModalOpened(true)}
          >
            <Share size={20} />
          </Button>
        </Group>
      </CenterPage>
    </>
  )
}