import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    lng: 'en',
    resources: {
        en: {
            common: require('./locales/en/common.json'),
            upload: require('./locales/en/pages/upload.json'),
            drops: require('./locales/en/pages/drops.json'),
            download: require('./locales/en/pages/download.json'),
            settings: require('./locales/en/pages/settings.json'),
            home: require('./locales/en/pages/home.json'),
        },
        fr: {
            // translations: require('./locales/fr/translations.json')
        },
    },
    ns: [
        'common', 
        'upload',
        'drops',
        'download',
        'settings',
        'home',
    ],
    defaultNS: 'common'
});

i18n.languages = ['en', 'fr'];

export default i18n;