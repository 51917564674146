import { createStyles, Modal } from "@mantine/core";

export function GlassModal(props: any) {
  const useClasses = createStyles((theme, _params, getRef) => ({
    modal: {
      zIndex: 1000,

      '& .mantine-Modal-content': {
        background: 'none',
        boxShadow: 'none',
      }
    }
  }));

  const { classes } = useClasses();

  return (
    <Modal
      opened={props.opened}
      onClose={props.onClose}
      size={props.size || 'sm'}
      overlayProps={{
        opacity: props.overlayOpacity || 0.7,
        blur: props.overlayBlur || 5,
      }}
      withCloseButton={false}
      className={classes.modal}
      {...props}
    >
      {props.children}
    </Modal>
  )
}