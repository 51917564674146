import { API_URL } from "../consts";
import { Drop } from "./drop";
import { FileObject } from "./fileObject";

export class User {
  id: string;
  drops: Drop[];
  maxStorage: number;
  maxUpload: number;
  isProUser: boolean;

  /** 
   * Creates an user object
   * @param id - unique user identifier supplied by the auth server
   * @param drops - any drops this user owns
   */
  constructor(
    id: string,
    drops: Drop[],
    isProUser: boolean,
    maxStorage: number,
    maxUpload: number,
  ) {
    this.id = id;
    this.drops = drops;
    this.isProUser = isProUser;
    this.maxStorage = maxStorage;
    this.maxUpload = maxUpload;
  }

  /** Checks how much data this user's drops have used */
  checkDataUsage() {
    let usedStorage = this.drops.reduce((acc, cur) => {
      return acc + cur.files.reduce((fAcc, fCur) => {
        return fAcc + fCur.size;
      }, 0);
    }, 0);
    return usedStorage;
  }
}

export async function getUserByToken(token: string) {
  let response = await fetch(`${API_URL}user`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  });
  if (!response.ok) return;

  let json: User = await response.json();
  let user = new User(
    json.id,
    json.drops.map((drop: Drop) => new Drop(
      drop.id,
      drop.creatorID,
      drop.name,
      drop.expireDate,
      drop.files.map((file: FileObject) => new FileObject(
        file.id,
        file.name,
        file.size,
        null
      )),
    )),
    json.isProUser,
    json.maxStorage,
    json.maxUpload,
  );

  return user;
}