import { Stack } from "@mantine/core";
import PropTypes from 'prop-types';

export default function PageHeader({
    height,
    children
}) { 
    return (
        <Stack
            style={{
                height: height,
                justifyContent: 'center',
            }}
        >
            { children }
        </Stack>
    )
}

PageHeader.propTypes = { 
    height: PropTypes.number,
    children: PropTypes.node.isRequired,
}

PageHeader.defaultProps = {
    height: 150,
}