import brand from '../../assets/images/brand.png';
import { Button, Center, Group, Navbar, Space, Stack } from '@mantine/core';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { CloudUpload, Download, Files, Logout, Settings } from 'tabler-icons-react';
import DesktopNavbarButton from './DesktopButton';
import { useTranslation } from "react-i18next";

export default function DesktopNav({
  currentPage
}) { 

  /** Hooks */
  const { t } = useTranslation();
  const pageNavigate = useNavigate();

  /** Sign out */
  function signOut() { 
    window.localStorage.removeItem('token');
    pageNavigate('/');
  }

  return ( 
    <Navbar
      fixed
      height='100vh'
      width={{ base: 300 }}
      p='sm'
      sx={(theme) => ({
        backgroundColor: theme.colorScheme === 'dark' ? 
        theme.colors.gray[9] : theme.colors.gray[1],
        border: 'none',
      })}
    >
      {/* Logo section */}
      <Navbar.Section>
        {/* Temp */}
        <Center style={{ height: 150 }}>
          <img
            src={ brand }
            style={{
              width: 200,
            }}
          />
        </Center>
      </Navbar.Section>

      {/* Quick action buttons */}
      <Navbar.Section>
        <Group grow>
          <Button
            radius='md'
            size='md'
            color='dark'
            variant='subtle'
            component={ Link }
            to='/?receive=true'
          >
            <Download strokeWidth={ 1.5 } />
          </Button>
          <Button
            size='md'
            radius='md'
            color='dark'
            variant='subtle'
            onClick={() => signOut() }
          >
            <Logout strokeWidth={ 1.5 } />
          </Button>
        </Group>
      </Navbar.Section>

      <Space h='md' />

      {/* Buttons */}
      <Navbar.Section grow>
        <Stack
          style={{ gap: 5 }}
        >
          <DesktopNavbarButton
            text={ t('navButtons.create', { ns: 'common' }) }
            icon={ <CloudUpload strokeWidth={ 1.5 } /> }
            isHighlighted={ currentPage === 'upload' }
            component={ Link }
            to='/upload'
          />
          <DesktopNavbarButton
            text={ t('navButtons.drops', { ns: 'common' }) }
            icon={ <Files strokeWidth={ 1.5 } /> }
            isHighlighted={ currentPage === 'drops' }
            component={ Link }
            to='/drops'
          />
          <DesktopNavbarButton
            text={ t('navButtons.settings', { ns: 'common' }) }
            icon={ <Settings strokeWidth={ 1.5 } /> }
            isHighlighted={ currentPage === 'settings' }
            component={ Link }
            to='/settings'
          />
        </Stack>
      </Navbar.Section>
    </Navbar>
  )
}

DesktopNav.propTypes = { 
  currentPage: PropTypes.string.isRequired,
}