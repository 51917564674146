import { Button, Center, Grid, Group, Loader, Modal, PasswordInput, PinInput, Stack, Text, TextInput, Title } from "@mantine/core";
import { FormEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ArrowRight, Lock, Mail } from "tabler-icons-react";
import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { APP_TOKEN, AUTH_URL } from "../func/consts";
import { useDisclosure } from "@mantine/hooks";

export default function SignIn(props: any) {

	// Hooks
	const { t } = useTranslation("home");
	const pageNavigate = useNavigate();
	const [opened, { open, close }] = useDisclosure(false);

	// States
	const [emailValue, setEmailValue] = useState("");
	const [passwordValue, setPasswordValue] = useState("");
	const [otpValue, setOtpValue] = useState("");
	const [loading, setLoading] = useState(false);

	// Effect
	useEffect(() => { if (otpValue.length >= 6) { signIn(null); } });

	// Functions
	async function signIn(event: FormEvent) {
		if (event) event.preventDefault();

		// Check for empty values
		if (!emailValue || !passwordValue) {
			showNotification({
				title: t("errors.fillAllFields.title"),
				message: t("errors.fillAllFields.message"),
				color: "red"
			});
			return;
		}

		setLoading(true);

		// Send to the auth server
		try {
			const response = await fetch(`${AUTH_URL}auth/login`, {
				method: "POST",
				body: JSON.stringify({
					email: emailValue,
					password: passwordValue,
					otpToken: otpValue,
				}),
				headers: { 
					"Av-App-Token": APP_TOKEN,
				}
			});
			setLoading(false);

			if (!response.ok) {
				switch (response.status) {
					case 402: {
						// User has a 2FA but has not entered it/entered it incorrectly
						setOtpValue("");
						open();
						return;
					}
					case 404: {
						showNotification({
							title: t("errors.accountNotFound.title"),
							message: t("errors.accountNotFound.message"),
							color: "red"
						});
						return;
					} case 403: {
						showNotification({
							title: t("errors.accountNotConfirmed.title"),
							message: t("errors.accountNotConfirmed.message"),
							color: "red"
						});
						return;
					} case 401: {
						showNotification({
							title: t("errors.incorrectPassword.title"),
							message: t("errors.incorrectPassword.message"),
							color: "red"
						});
						return;
					} default: {
						showNotification({
							title: t("errors.unknownError.title", { ns: "common", status: response.status }),
							message: t("errors.unknownError.message", { ns: "common" }),
							color: "red"
						});
					}
				}
				return;
			}

			// Save the token to local storage
			const userInfo = await response.json();
			localStorage.setItem("token", userInfo.token);

			pageNavigate("/upload");
			setLoading(false);
		} catch (e) {
			showNotification({
				title: t("errors.unknownError.title", { ns: "common", status: "500" }),
				message: t("errors.unknownError.message", { ns: "common" }),
				color: "red"
			});
			return;
		}
	}

	// Return
	return (
		<>
			{/* OTP modal */}
			<Modal
				opened={opened}
				onClose={close}
				radius='md'
				centered
				closeOnEscape
				closeOnClickOutside
				withCloseButton={false}
			>
				<form onSubmit={(event) => signIn(event)}>
					<Stack>
						<Text align="center">{t("otp.text")}</Text>
						<Center>
							<Group>
								<PinInput
									length={6}
									variant="filled"
									size="md"
									radius="md"
									inputMode="numeric"
									inputType="number"
									value={otpValue}
									onChange={setOtpValue}
									disabled={loading}
									autoFocus
								/>


								<Button
									type="submit"
									radius="md"
									size="sm"
									disabled={loading}
								>
									{!loading ? <ArrowRight /> : <Loader
										color="dark"
										size="sm" />
									}
								</Button>
							</Group>
						</Center>
					</Stack>
				</form>
			</Modal>

			<form onSubmit={(event) => signIn(event)}>
				<Grid grow gutter="sm" style={{
					maxWidth: 500,
					margin: 20
				}}>
					<Grid.Col span={8}>
						<Title
							order={1}
							style={{ textAlign: "left" }}
						>
							{t("titles.signIn")}
						</Title>
					</Grid.Col>
					<Grid.Col span={8}>
						<TextInput
							placeholder={t("inputs.email.placeholder")}
							icon={<Mail size={14} />}
							variant="filled"
							radius="md"
							size="md"
							onChange={(event) => setEmailValue(event.currentTarget.value)}
							disabled={loading}
							value={emailValue}
							autoFocus
						/>
					</Grid.Col>
					<Grid.Col span={7}>
						<PasswordInput
							placeholder={t("inputs.password.placeholder")}
							icon={<Lock size={14} />}
							variant="filled"
							radius="md"
							size="md"
							onChange={(event) => setPasswordValue(event.currentTarget.value)}
							disabled={loading}
							value={passwordValue}
						/>
					</Grid.Col>
					<Grid.Col span={1}>
						<Button
							type="submit"
							radius="md"
							size="md"
							style={{ width: "100%" }}
							disabled={loading}
						>
							{!loading ? <ArrowRight /> : <Loader
								color="dark"
								size="sm" />
							}
						</Button>
					</Grid.Col>

					<Grid.Col span={8}>
						<Text>
							{t("texts.avant.preBold")}
							<Text
								component="span"
								weight={800}
							>
								{t("texts.avant.bold")}
							</Text>
							{t("texts.avant.postBold")}
							<Text
								variant="link"
								component="a"
								href="https://avant.isaacshea.com/"
							>
								{t("texts.avant.link")}
							</Text>
						</Text>
					</Grid.Col>

					{/* MOBILE BUTTON */}
					{props.isMobile === false ? null :
						<>
							<Grid.Col span={8}>
								<Title
									order={2}
									style={{ textAlign: "center" }}
								>
									{t("titles.or")}
								</Title>
							</Grid.Col>
							<Grid.Col span={8}>
								<Button
									component={Link}
									to="/?receive=true"
									color="dark"
									radius="md"
									size="md"
									style={{ width: "100%" }}
									sx={(theme) => ({
										color: "white"
									})}
								>
									{t("inputs.buttons.receiveFile")}
								</Button>
							</Grid.Col>
						</>
					}
				</Grid>
			</form>
		</>
	)
}