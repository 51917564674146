import SignIn from "../components/signIn";
import { Center, SimpleGrid } from "@mantine/core";
import { useDocumentTitle } from "@mantine/hooks";

export default function Login() {

	// Document title
	useDocumentTitle("Log in  • Filedrop");

	// Return
	return (
		<SimpleGrid
			cols={1}
			style={{ height: "100vh" }}
			sx={(theme) => ({
				backgroundColor: theme.colors.white,
			})}
		>
			<Center
				style={{
					height: "100%",
				}}
			>
				<SignIn isMobile={true} />
			</Center>
		</SimpleGrid>
	)
}