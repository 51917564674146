import PropTypes from 'prop-types';
import { Group, Stack, Text } from "@mantine/core";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

export default function ComplexButton({
  title,
  subtitle,
  buttons,
  isButton,
  onClick,
}) {
  /** Hooks */
  const [isHover, setIsHover] = useState(false);

  return (
    <Group
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      sx={(theme) => ({
        backgroundColor: isHover && isButton ?
          theme.colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.gray[2]
          :
          theme.colorScheme === 'dark' ? theme.colors.gray[9] : theme.colors.gray[1],
        borderRadius: theme.radius.md,
        padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
        flexWrap: 'nowrap',
        textDecoration: 'none',
        cursor: isButton ? 'pointer' : 'default',
      })}
      onClick={onClick}
    >
      <Stack
        style={{
          flexGrow: 1,
          gap: 5,
        }}
      >
        <Text
          weight={800}
          size='lg'
        >
          {title}
        </Text>
        <Text
          size='xs'
        >
          {subtitle}
        </Text>
      </Stack>

      {buttons}
    </Group>
  )
}

ComplexButton.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  buttons: PropTypes.node,
  isButton: PropTypes.bool,
  to: PropTypes.string,
}