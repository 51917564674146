import { AppShell, Stack } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import PropTypes from 'prop-types';
import DesktopNav from './DesktopNavbar';
import MobileNav from './MobileNavbar';

export default function PowerAppShell({
  currentPage,
  children,
}) {
  const { width } = useViewportSize();
  const isMobile = width <= 1000;

  return (
    <>
      <AppShell
        padding='md'
        navbar={
          isMobile ?
            <MobileNav
              currentPage={currentPage}
            />
            :
            <DesktopNav
              currentPage={currentPage}
            />
        }
      >
        <Stack>
          {children}
        </Stack>
      </AppShell>
    </>
  )
}

PowerAppShell.propTypes = {
  currentPage: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}