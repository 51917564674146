import SignIn from "../components/signIn";
import FileCode from "../components/fileCode";
import { Center, SimpleGrid } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserByToken } from "../func/models/user";

export default function Home() {

	// Hooks
	const isMobile = useMediaQuery("(max-width: 1000px)");
	const pageNavigate = useNavigate();

	// Redirect the user to their default page
	const search = useLocation().search;
	async function checkDefaultPage() {

		// Check if the user has been redirected here from the "receive file" button
		const isReceiving = new URLSearchParams(search).get("receive");
		if (isReceiving) return;

		// Check if the user is signed in
		const token = localStorage.getItem("token");
		if (!token) return;

		// Check if the user can be authenticated before moving around
		const user = await getUserByToken(token);
		if (!user) return;

		// Check if the user wants to be redirected
		const defaultPage = localStorage.getItem("defaultPage");
		if (defaultPage === "upload") { pageNavigate("/upload"); }
		else if (defaultPage === "drops") { pageNavigate("/drops"); }
	}
	useEffect(() => { checkDefaultPage() }, []);

	// Return
	return (
		<SimpleGrid
			cols={isMobile === true ? 1 : 2}
			style={{ height: "100vh" }}
			sx={(theme) => ({
				backgroundColor: theme.colors.cyan[5],
			})}
		>
			{isMobile === false ?
				<Center
					style={{
						height: "100%",
						boxShadow: "0px 0px 50px 0px rgba(0,0,0,0.2)"
					}}
					sx={(theme) => ({
						backgroundColor: theme.white
					})}
				>
					<SignIn isMobile={isMobile} />
				</Center>
				: null
			}
			<Center
				style={{ height: "100%" }}
				sx={(theme) => ({
					color: theme.white
				})}
			>
				<FileCode isMobile={isMobile} />
			</Center>
		</SimpleGrid>
	)
}