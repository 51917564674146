import { Group, Text, UnstyledButton } from "@mantine/core";
import PropTypes from 'prop-types';
import { useState } from "react";

export default function DesktopNavbarButton({
  text,
  icon,
  isHighlighted,
  ...props
}) {
  const [isHover, setIsHover] = useState(false);

  return (
    <UnstyledButton
      sx={(theme) => ({
        width: '100%',
        backgroundColor: isHighlighted ?
          isHover ?
            theme.colors.cyan[6] : theme.colors.cyan[5]
          : isHover ?
            theme.colorScheme === 'dark' ?
              theme.colors.gray[7] : theme.colors.gray[3]
            : null,
        color: isHighlighted ?
          theme.white : theme.black,
        padding: `${theme.spacing.xs} ${theme.spacing.md}`,
        borderRadius: theme.radius.md,
      })}
      {...props}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Group>
        <Text
          weight={isHighlighted ? 800 : 400}
          style={{
            flexGrow: 1
          }}
        >
          {text}
        </Text>

        {icon}
      </Group>
    </UnstyledButton>
  )
}

DesktopNavbarButton.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  isHighighted: PropTypes.bool,
}