import { Stack } from "@mantine/core";

export default function ButtonStack(props: any) {
  return (
    <Stack
      sx={(theme) => ({
        gap: 5,

        '& > button, > a, > div': {
          borderRadius: theme.radius.sm,
        },
        '& > button:first-of-type, > a:first-of-type, > div:first-of-type': {
          borderRadius: `${theme.radius.md}px 
                        ${theme.radius.md}px 
                        ${theme.radius.sm}px 
                        ${theme.radius.sm}px`
        },
        '& > button:last-child, > a:last-child, > div:last-child': {
          borderRadius: `${theme.radius.sm}px 
                        ${theme.radius.sm}px 
                        ${theme.radius.md}px 
                        ${theme.radius.md}px`
        },
        '& > button:only-of-type, > a:only-of-type, > div:only-of-type': {
          borderRadius: theme.radius.md
        }
      })}
    >
      {props.children}
    </Stack>
  )
}