import { ActionIcon, Button, Loader, Modal, Space, Stack, Text, TextInput, Title } from "@mantine/core";
import PowerAppShell from "../components/appShell/Main";
import CenterPage from "../components/layout/centerPage";
import PageHeader from "../components/layout/pageHeader";
import { useTranslation } from "react-i18next";
import { LetterCase, Trash } from "tabler-icons-react";
import FullscreenDropzone from "../components/inputs/FullscreenDropzone";
import { useEffect, useState } from "react";
import { getUserByToken, User } from "../func/models/user";
import { useNavigate } from "react-router-dom";
import { normaliseBytes } from "../func/misc/helpers";
import { showNotification } from "@mantine/notifications";
import ComplexButton from "../components/inputs/buttons/complexButton";
import ButtonStack from "../components/inputs/buttons/buttonStack";
import { Drop } from "../func/models/drop";
import { API_URL } from "../func/consts";
import { useDocumentTitle } from "@mantine/hooks";

export default function UploadPage() {
  const { t } = useTranslation("upload");
  const pageNavigate = useNavigate();
  useDocumentTitle("Create a drop • Filedrop");

  /** Hooks */
  const [name, setName] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [user, setUser] = useState<User>(new User(
    "",
    [],
    false,
    0,
    0
  ));
  const [currentUploadItem, setCurrentUploadItem] = useState("");

  /** Accept inputs & add files to the list */
  function addFiles(fs: File[]) {
    if (!user) return;
    console.log("adding files", fs);

    /** Check file sizes, etc. */
    let addFiles: File[] = [];
    for (var i = 0; i < fs.length; i++) {
      var file = fs[i];

      /** File is too large to upload */
      if (file.size > user.maxUpload) {
        let excess = file.size - user.maxUpload;
        console.log("oh no");

        showNotification({
          title: t("errors.fileTooLarge.title", {
            filename: file.name,
            excess: normaliseBytes(excess),
          }),
          message: t("errors.fileTooLarge.message", {

          }),
          color: "red",
        });
        continue;
      }

      /** File exceeds remaining storage */
      if (user.checkDataUsage() + file.size > user.maxStorage) {
        let excess = (user.checkDataUsage() + file.size) - user.maxStorage;

        showNotification({
          title: t("errors.fileExceedStorage.title", {
            filename: file.name,
            excess: normaliseBytes(excess),
          }),
          message: t("errors.fileExceedStorage.message", {

          }),
          color: "red",
        });
        continue;
      }

      addFiles.push(file);
    }

    /** Add to files list */
    setFiles(files.concat(addFiles));

    /** If the name is empty, set the drop name to the first file"s name */
    if (!name) setName(addFiles[0].name);
  }

  /** Upload drop & move to drop page */
  async function uploadDrop() {
    const token = localStorage.getItem("token");
    if (!token) pageNavigate("/");

    /** Check for files */
    if (files.length === 0) {
      showNotification({
        title: t("errors.noFiles.title"),
        message: t("errors.noFiles.message"),
        color: "red",
      });
      return;
    }

    /** If the name is empty, set the drop name to the first file"s name */
    if (!name) setName(files[0].name);

    /** Create a drop on the server */
    setCurrentUploadItem("setup");
    let dropRes = await fetch(`${API_URL}drop/create`, {
      method: "PUT",
      body: JSON.stringify({
        name: name,
      }),
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });
    if (!dropRes.ok) {
      showNotification({
        title: t("errors.unknownError.title", {
          ns: "common",
          status: dropRes.status,
        }),
        message: t("errors.unknownError.message", {
          ns: "common",
        }),
        color: "red",
      });
      setCurrentUploadItem("");
      return;
    }

    let dropInfo = await dropRes.json()
    const drop = new Drop(
      dropInfo.id,
      dropInfo.creatorID,
      dropInfo.name,
      dropInfo.expireDate,
      dropInfo.files,
    );

    /** Add files to drop */
    for (var file of files) {
      setCurrentUploadItem(file.name);
      let fileRes = await fetch(`${API_URL}drop/${drop.id}/file?name=${file.name}`, {
        method: "PUT",
        body: file,
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": file.type,
        }
      });
      if (!fileRes.ok) {
        showNotification({
          title: t("errors.unknownError.title", {
            ns: "common",
            status: fileRes.status,
          }),
          message: t("errors.unknownError.message", {
            ns: "common",
          }),
          color: "red",
        });
        setCurrentUploadItem("");
        return;
      }
    }

    /** Go to the drop page */
    window.open(`${window.location.origin}/drop?code=${drop.id}`, "_blank");
    pageNavigate(`../drops`);
  }

  /** Get & save user data */
  async function setUserData() {
    const token = localStorage.getItem("token");
    if (!token) pageNavigate("/");

    let user = await getUserByToken(token);
    if (!user) pageNavigate("/");
    setUser(user);
  }

  /** Run on page load */
  useEffect(() => {
    setUserData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {/* UPLOAD PROGRESS MODAL */}
      <Modal
        withCloseButton={false}
        centered
        opened={currentUploadItem !== ""}
        onClose={() => setCurrentUploadItem("")}
        closeOnClickOutside={false}
        closeOnEscape={false}
        radius="md"
      >
        <Stack align="center">
          <Loader size={50} />
          <Text
            size="xs"
            align="center"
          >
            {t("texts.uploadMessage", { item: currentUploadItem })}
          </Text>
        </Stack>
      </Modal>

      {/* MAIN PAGE */}
      <PowerAppShell
        currentPage="upload"
      >
        <CenterPage alignTop>
          {/* Page header */}
          <PageHeader>
            <Title order={1}>
              {t("titles.create")}
            </Title>
          </PageHeader>

          {/* Name input */}
          <TextInput
            placeholder={t("inputs.name.placeholder")}
            icon={<LetterCase />}
            variant="filled"
            size="md"
            radius="md"
            value={name}
            onChange={(event) => setName(event.currentTarget.value)}
            onBlur={() => setName(name.trim())}
          />

          {/* File upload */}
          <FullscreenDropzone
            titleMobile={t("inputs.dropzone.titleMobile")}
            titleDesktop={t("inputs.dropzone.titleDesktop")}
            subtitle={t("inputs.dropzone.subtitle", { size: user ? normaliseBytes(user.maxUpload) : "xx" })}
            setFiles={addFiles}
          />

          {/* Files section */}
          <Title order={2}>
            {t("titles.files")}
          </Title>
          <Text>
            {t("texts.dropStorage", {

              dropSize: normaliseBytes(files.reduce((acc, cur) => {
                return acc + cur.size;
              }, 0)),
              totalUsed: normaliseBytes(
                user.checkDataUsage() +
                files.reduce((acc, cur) => {
                  return acc + cur.size;
                }, 0)
              ),
              totalStorage: normaliseBytes(user.maxStorage)
            })}
          </Text>

          <ButtonStack>
            {files.map((file, index) =>
              <ComplexButton
                onClick={null}
                key={index}
                title={file.name}
                subtitle={normaliseBytes(file.size)}
                isButton={false}
                buttons={
                  <ActionIcon
                    radius="xl"
                    color="red"
                    onClick={() => {
                      let filteredItems = files.splice(index, 1);
                      setFiles(files.filter(n => !filteredItems.includes(n)));

                      showNotification({
                        message: t("alerts.removeFile.message", {

                          name: file.name
                        }),
                        color: "cyan",
                      });
                    }}
                  >
                    <Trash />
                  </ActionIcon>
                }
              />
            )}
          </ButtonStack>

          <Space h="xs" />

          <Button
            size="md"
            radius="md"
            onClick={() => uploadDrop()}
          >
            {t("inputs.buttons.upload")}
          </Button>
        </CenterPage>
      </PowerAppShell>
    </>
  )
}